








import { defineComponent, PropType } from '@vue/composition-api';
import { carouselSlideWhitelist, getComponents } from './helpers';
import { TAllowedCarouselSlideThumbnail } from './types';

export default defineComponent({
  name: 'StoryblokCarouselSlide',
  inheritAttrs: false,
  components: getComponents(),
  props: {
    thumbnails: {
      type: Array as PropType<TAllowedCarouselSlideThumbnail[]>,
      required: true,
    },
  },
  setup(props) {
    const [thumbnail] = props.thumbnails || [];

    const validThumbnail = carouselSlideWhitelist.has(thumbnail?.component);
    if (!validThumbnail) {
      return {
        thumbnail: null,
      };
    }

    return {
      thumbnail,
    };
  },
});
