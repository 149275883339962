import { computed, ref, readonly } from '@vue/composition-api';

const state = ref<{
  activeSlide: null | string,
}>({
  activeSlide: null,
});

const activeSlide = computed(() => state.value.activeSlide);

const setActiveSlide = (id: string) => {
  state.value.activeSlide = id;
};

const isActiveSlide = (id: string) => activeSlide.value === id;

export const activeSlideStore = readonly({
  activeSlide,
  isActiveSlide,
  setActiveSlide,
});
