import { ICarouselSlideComponents } from './types';

export const carouselSlideWhitelist = new Set([
  'Thumbnail',
  'TrainerThumbnail',
  'BasicThumbnail',
  'WorkoutCard',
  'ReviewThumbnail',
]);

export function getComponents() {
  const components: ICarouselSlideComponents = {};

  carouselSlideWhitelist.forEach(name => {
    components[name] = () => import(`~/components/storyblokDynamic/${name}/${name}.vue`);
  });

  return components;
}
