












































import { defineComponent, PropType, computed } from '@vue/composition-api';
import ClickableProductItem from './ClickableProductItem.vue';

type AccessoriesIncludes = [];
type BasePackageIncludes = {
  basePackage: [];
  extras: [];
}
type Includes = BasePackageIncludes | AccessoriesIncludes;

export default defineComponent({
  name: 'ProductsIncludedItems',
  components: {
    ClickableProductItem,
  },
  props: {
    includes: {
      type: [Object, Array] as PropType<Includes>,
      required: true,
    },
    gifts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    return {
      basePackage: computed(() => props.includes?.basePackage ?? props.includes),
      extras: computed(() => props.includes?.extras ?? []),
      title: computed(() => (props.includes?.extras?.length > 0 ? 'Base Package' : 'Includes')),
    };
  },
});
