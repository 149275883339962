








































import { defineComponent, computed, PropType } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { useModal } from '~/composables/useModal';
import { IProduct } from '~/types/fightcamp';

interface IProductPreview extends IProduct {
  name: string;
  image: {
    src: string,
    alt: string,
  };
  tag: string;
  carouselImages?: string[];
  product?: {
    marketplace_img_url: string;
  };
}

export default defineComponent({
  name: 'ClickableProductItem',
  components: { FCImage },
  props: {
    product: {
      type: Object as PropType<IProductPreview>,
      required: true,
    },
  },
  setup(props) {
    const isClickableProduct = computed(() => (props.product?.carouselImages?.length ?? 0) > 0 || props.product?.description);

    function openLightbox() {
      const width = Math.min(500, Math.floor(window?.innerWidth * 0.85));

      const modalHeight = props.product.carouselImages ? 648 : 600;
      const height = Math.min(modalHeight, Math.floor(window?.innerHeight * 0.85));

      const componentName = props.product.carouselImages ? 'AccessoryMultiLightbox' : 'FCLightBox';
      const { modalShow } = useModal({
        component: () => import(`~/components/${componentName}.vue`),
        componentProps: { item: props.product },
        modalProps: {
          componentName,
          width,
          height,
        },
      });

      modalShow();
    }

    const imgSrc = computed(() => props?.product?.image?.src ?? props?.product?.marketplace_img_url ?? props?.product?.product?.marketplace_img_url ?? '');
    const imgAlt = computed(() => props?.product?.image?.alt || props?.product?.title);
    const productName = computed(() => props?.product?.name || props?.product?.title);
    const provider = computed(() => (imgSrc.value.includes('storyblok') ? 'storyblok' : 'fcProvider'));
    const productTag = computed(() => props?.product?.tag);

    return {
      openLightbox,
      imgSrc,
      imgAlt,
      productName,
      provider,
      productTag,
      isClickableProduct,
    };
  },
});
