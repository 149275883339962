






























import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import { Carousel as AUICarousel, Slide as AUISlide } from '@fc/angie-ui';
import { isMobileEffect, isDesktopEffect } from '~/effects/matchMedia';
import { activeSlideStore } from '~/components/storyblokDynamic/TrainerThumbnail/activeSlide.store';
import { useDraggable, useMousePressed } from '@vueuse/core';
import throttle from 'lodash/throttle';
import { ICarouselAttrs, CarouselMode } from './types';

export default defineComponent({
  name: 'StoryblokCarousel',
  components: {
    AUICarousel,
    AUISlide,
  },
  inheritAttrs: false,
  setup(_, context) {
    const carouselReplacementRef = ref<HTMLElement>();
    const isMobile = isMobileEffect();
    const isDesktop = isDesktopEffect();

    const {
      label,
      slides,
      activeMobile,
      activeDesktop,
      mode,
      hideNavigation,
      contentWrap = 'default',
      staticHorizontalOffset,
    } = (context.attrs ?? {}) as ICarouselAttrs;

    const isThreeSlideView = computed(() => (mode === CarouselMode.threeSlideView));

    const carouselBindings = {
      screenLabel: label,
      infiniteSlidesEnabled: true,
      hideNavigation,
    };

    const carouselActive = computed(() => (isMobile.value && activeMobile || isDesktop.value && activeDesktop));

    onMounted(() => {
      const [firstSlide] = slides ?? [];
      const [firstThumbnail] = firstSlide?.thumbnails ?? [];

      activeSlideStore.setActiveSlide(firstThumbnail?._uid);
    });

    if (isDesktop.value && !activeDesktop) {
      const { x } = useDraggable(carouselReplacementRef);
      const { pressed } = useMousePressed({ target: carouselReplacementRef });

      watch([pressed, x], throttle(([pressedNewVal, newValX]) => {
        if (pressedNewVal && carouselReplacementRef.value) {
          carouselReplacementRef.value.scrollLeft -= Math.round(newValX);
        }
      }, 25));
    }

    return {
      carouselReplacementRef,
      containerComponentClass: computed(() => ([
        (staticHorizontalOffset ?? '').length ? 'overflow-effect' : '',
      ])),
      carouselComponent: computed(() => {
        if (carouselActive.value) {
          if (isThreeSlideView.value) {
            return {
              name: 'AUICarousel',
              bindings: {
                ...carouselBindings,
                threeSlideView: true,
                slideWidthPercentage: 0.65,
              },
            };
          }
          return {
            name: 'AUICarousel',
            bindings: carouselBindings,
          };
        }

        return {
          name: 'div',
          bindings: {
            class: [
              'carousel-replacement',
              'fc-padding-y-0 fc-padding-x-4',
              contentWrap,
            ].filter(c => c !== null),
            style: {
              paddingLeft: staticHorizontalOffset,
            },
          },
        };
      }),
      slideComponent: computed(() => {
        if (carouselActive.value) {
          return {
            name: 'AUISlide',
            bindings: { },
          };
        }

        return {
          name: 'div',
          bindings: {
            class: 'carousel-slide-replacement',
          },
        };
      }),
      slides,
      isThreeSlideView,
    };
  },
});
