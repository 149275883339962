import { Data } from '@vue/composition-api';
import { IThumbnailExpectedAttrs } from '~/components/storyblokDynamic/Thumbnail/types';
import { IBasicThumbnailAttrs } from '~/components/storyblokDynamic/BasicThumbnail/types';
import { IWorkoutCardAttrs } from '~/components/storyblokDynamic/WorkoutCard/types';
import { IReviewThumbnailProps } from '~/components/storyblokDynamic/ReviewThumbnail/types';
import { Component } from 'vue';

export interface ICarouselAttrs extends Data {
  component: 'Carousel';
  label: string,
  slides: ICarouselSlideAttrs[]
  activeMobile?: boolean,
  activeDesktop?: boolean,
  mode?: CarouselMode,
  hideNavigation?: boolean,
  contentWrap?: 'default' | 'wrap',
  staticHorizontalOffset?: string;
}

export type TAllowedCarouselSlideThumbnail = IThumbnailExpectedAttrs | IBasicThumbnailAttrs | IWorkoutCardAttrs | IReviewThumbnailProps;

export interface ICarouselSlideAttrs extends Data {
  component: 'CarouselSlide';
  thumbnails: TAllowedCarouselSlideThumbnail[];
}

export enum CarouselMode {
  default = 'default',
  threeSlideView = 'threeSlideView',
}

export interface ICarouselSlideComponents {
  [key: string]: () => Promise<Component>;
}
